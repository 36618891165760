import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import './sentry.scss';

const ENVIRONMENT = process.env.ENVIRONMENT;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
const SENTRY_RELAY = process.env.SENTRY_RELAY;
let SENTRY_DSN = process.env.SENTRY_DSN;

if(SENTRY_RELAY) {
    SENTRY_DSN = SENTRY_DSN.replace('sentry.oskarwegner.pl', window.location.host+'/error-logs')
}

if(document.querySelector('meta[data-user]') !== null) {
    let user = JSON.parse(document.querySelector('meta[data-user]').dataset.user);

    if(user) {
        let sentryUser = {
            id: user.id,
            username: user.username,
            name: user.username,
        };
        if(user.username.indexOf('@')) {
            sentryUser.email = user.username;
        }

        Sentry.setUser(sentryUser);
    }
}

function showReportDialog(eventId) {
    Sentry.showReportDialog({
        eventId: eventId,
        title: 'Ups! Wygląda na to, że mamy problem.',
        subtitle: 'Nasz zespół został poinformowany.',
        subtitle2: 'Opisz nam jaką operację próbowałeś wykonać kiedy wystąpił błąd.',
        labelName: 'Osoba zgłaszająca',
        labelEmail: 'Adres e-mail',
        labelClose: 'Zamknij',
        labelSubmit: 'Wyślij',
        labelComments: 'Co się stało?',
        errorGeneric: 'Wystąpił nieznany błąd podczas wysyłania zgłoszenia. Spróbuj ponownie.',
        errorFormEntry: 'Sprawdź czy wszystkie pola zostały poprawnie uzupełnione',
        successMessage: 'Zgłoszenie zostało wysłane. Dziękujemy!',
        onLoad() {
            let sentry = document.querySelector('.sentry-error-embed-wrapper');

            if(sentry) {
                let name = sentry.querySelector('#id_name');
                if(name) {
                    name.setAttribute('placeholder', 'Jan');
                }

                let email = sentry.querySelector('#id_email');
                if(email) {
                    email.setAttribute('placeholder', 'jan.kowalski@wp.pl');
                }
                let comment = sentry.querySelector('#id_comments');
                if(comment) {
                    comment.setAttribute('placeholder', 'Kliknąłem "X" i wystąpił problem.');
                }

                let branding = sentry.querySelector('.powered-by');
                if(branding) {
                    branding.remove();
                }
            }
        }
    });
}

Sentry.init({
    environment: ENVIRONMENT,
    release: SENTRY_RELEASE,
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend: (event, hint) => {
        if (event.exception) {
            showReportDialog(event.event_id);
        }
        return event;
    },
    tracesSampleRate: 0.2
});

if(document.querySelector('body[data-sentry-last-event-id]') !== null) {
    let id = document.querySelector('body[data-sentry-last-event-id]').dataset.sentryLastEventId;

    if(id) {
        setTimeout(function() {
            showReportDialog(id);
        }, 2500);
    }
}

window.Sentry = Sentry;
